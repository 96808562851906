<template>
    <section class="pt-4">
        <cargando v-if="cargando" />
        <div class="row mx-0 bg-white br-t-8 py-2 pr-3 align-items-center" class-header="f-16 text-general" header-row-class-name="text-general f-16 f-500">
            <p class="col-auto text-general f-18 f-500">
                Resultados de la busqueda
            </p>
            <div class="col-auto d-middle px-0 ml-auto">
                <i class="icon-history text-general" />
                <span class="text-general2 f-12 f-300 ml-2">Nuevo intento</span>
            </div>
            <div class="col-auto d-middle px-0 ml-3">
                <i class="icon-package-variant f-18 text-general-red" />
                <span class="text-general2 f-12 f-300 ml-2">Productos faltantes</span>
            </div>
            <div class="col-auto d-middle px-0 ml-3">
                <i class="icon-truck f-20 text-green mx-2" />
                <span class="text-general2 f-12 f-300 ml-2">En entrega</span>
            </div>
            <div class="col-3 d-middle px-0 ml-3">
                <el-input v-model="buscador" placeholder="Buscar" class="br-20 w-100 ml-auto" size="small" />
            </div>
            <el-tooltip placement="bottom" content="Filtro" effect="light">
                <div class="btn-action border ml-2 cr-pointer" @click="irFiltro">
                    <i class="icon-filter-outline f-20" />
                </div>
            </el-tooltip>
        </div>
        <tabla-general
        :data="pedidos.filter(data => !buscador || data.leechero.toLowerCase().includes(buscador.toLowerCase()))"
        class-header="f-16 text-general"
        :mostrar-buscador="false"
        header-row-class-name="text-general f-16 f-500"
        style="width: 100%"
        @filaFuncion="verDetalle"
        >
            <template slot="adicionales-izquierda">
                <el-table-column
                fixed
                class-name="text-center"
                width="150"
                >
                    <template slot-scope="scope">
                        <div class="row mx-0 justify-content-around">
                            <div class="col px-0">
                                <el-tooltip v-if="scope.row.total_entregas > 0" placement="bottom" content="En entrega" effect="light">
                                    <i class="icon-truck f-20 text-green mx-2" />
                                </el-tooltip>
                            </div>
                            <div class="col px-0">
                                <el-tooltip v-if="scope.row.nuevo_intento > 1" placement="bottom" content="Nuevo intento de entrega" effect="light">
                                    <i class="icon-history f-20 text-general mx-2" />
                                </el-tooltip>
                            </div>
                            <div class="col px-0">
                                <el-tooltip v-if="scope.row.productos_faltantes" placement="bottom" content="Productos faltantes" effect="light">
                                    <i class="icon-package-variant f-20 text-general-red mx-2" />
                                </el-tooltip>
                            </div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                fixed
                label="Pedido"
                width="100"
                >
                    <template slot-scope="scope">
                        <p>
                            {{ scope.row.id }}
                        </p>
                    </template>
                </el-table-column>
                <el-table-column
                label="Leechero"
                fixed
                width="180"
                class-name="text-center"
                >
                    <template slot-scope="scope">
                        <p>
                            {{ scope.row.leechero }}
                        </p>
                    </template>
                </el-table-column>
                <el-table-column
                prop="cliente"
                label="Cliente"
                class-name="text-center"
                min-width="180"
                />
                <el-table-column
                prop="cantidad_productos"
                label="Productos"
                min-width="120"
                class-name="text-center"
                />
                <el-table-column
                label="Valor"
                min-width="100"
                class-name="text-center"
                >
                    <template slot-scope="scope">
                        <p>
                            {{ separadorNumero(scope.row.valor_final_pedido) }}
                        </p>
                    </template>
                </el-table-column>
                <el-table-column
                prop="conductor_placa"
                label="Vehículo"
                min-width="100"
                />
                <el-table-column
                prop="conductor_nombre"
                label="Conductor"
                min-width="120"
                />
                <el-table-column
                prop="estado_leeche"
                label="Estado Leeche"
                class-name="text-center"
                width="180"
                />
                <el-table-column
                prop="estado_logistico"
                label="Estado logistica"
                class-name="text-center"
                width="180"
                />
                <el-table-column
                prop="total_peso"
                label="Peso (Kg)"
                min-width="120"
                class-name="text-center"
                />
                <el-table-column
                prop="total_volumen"
                label="Volumen (m3)"
                min-width="150"
                class-name="text-center"
                />
            </template>
        </tabla-general>
        <modal-filtro ref="modalFiltro" :filtros="info_filtros" @filtrar="filtrarEnvios" />
        <modal-detalle-pedido ref="modalDetalle" @update="getConsultarHistorialPedidos" />
    </section>
<!--
    <section class="h-100" :class="buscarPedido ? 'd-flex align-items-center justify-content-center' : ''">
        <div class="box-search flex-column justify-content-center">
            <div class="row mx-0 justify-center">
                <img src="/img/no-imagen/buscar.svg" width="120" height="120" class="mb-3" />
                <p class="col-12 text-center f-15 text-general f-300">
                    Define los parámetros de tu <br /> busqueda
                </p>
                <div class="btn-general px-5 f-14 mt-4 cr-pointer" @click="aplicarFiltro">
                    Buscar pedido
                </div>
            </div>
        </div>
        <modal-filtro ref="modalFiltro" />
    </section>
    -->
</template>
<script>

import Historial from "~/services/pedidos/pedidos-routing";
import { mapGetters } from 'vuex'

export default {
    components: {
        modalFiltro: () => import('./partials/modalFiltroHistorico'),
        modalDetallePedido: () => import('./partials/modalDetallesRuta'),

    },
    data(){
        return {
            buscarPedido: true,
            cargando: true,
            pedidos: [],
            info_filtros: {},
            buscador: '',
            //----------
            filtro_leechero: '',
            filtro_estado: '',
            filtro_conductor: '',
            filtro_vehiculo: '',
            filtro_logistico: '',
            filtro_valor_desde: '',
            filtro_valor_hasta: '',
            filtro_productos_desde: '',
            filtro_productos_hasta: '',
            filtro_peso_desde: '',
            filtro_peso_hasta: '',
            filtro_volumen_desde: '',
            filtro_volumen_hasta: '',
        }
    },

    computed: {
        ...mapGetters({
            id_moneda:'cedis/id_moneda',
        }),
    },

    watch: {
        id_moneda(){
            if (!this.cargando){
                this.validar()
            }
        }
    },

    created(){
        this.validar();
    },

    methods: {
        async validar(){
            if (this.id_moneda === null){
                setTimeout(async() => {
                    this.getConsultarHistorialPedidos();
                },1000);
            } else {
                this.getConsultarHistorialPedidos();
            }
        },

        async getConsultarHistorialPedidos(){
            try {
                let params =  {
                    id_moneda: this.id_moneda,
                    filtro_leechero: this.filtro_leechero,
                    filtro_conductor: this.filtro_conductor,
                    filtro_vehiculo: this.filtro_vehiculo,
                    filtro_logistico: this.filtro_logistico,
                    filtro_estado: this.filtro_estado,
                    filtro_valor_desde: this.filtro_valor_desde,
                    filtro_valor_hasta: this.filtro_valor_hasta,
                    filtro_productos_desde: this.filtro_productos_desde,
                    filtro_productos_hasta: this.filtro_productos_hasta,
                    filtro_peso_desde: this.filtro_peso_desde,
                    filtro_peso_hasta: this.filtro_peso_hasta,
                    filtro_volumen_desde: this.filtro_volumen_desde,
                    filtro_volumen_hasta: this.filtro_volumen_hasta,
                }
                const {data} = await Historial.getListarPedidosHistorial(params)
                this.pedidos = data.pedidos;
                this.cargando = false;
                if (this.isObjEmpty(this.info_filtros)){
                    this.info_filtros = data.info_filtros;
                }
            } catch (error){
                this.error_catch(error)
            }
        },

        filtrarEnvios(filtros){
            this.filtro_estado = filtros.estado_leeche;
            this.filtro_leechero = filtros.leechero;
            this.filtro_conductor = filtros.conductor;
            this.filtro_vehiculo = filtros.vehiculo;
            this.filtro_logistico = filtros.estado_logistico;
            this.filtro_valor_desde = filtros.valor_desde;
            this.filtro_valor_hasta = filtros.valor_hasta;
            this.filtro_productos_desde = filtros.productos_desde;
            this.filtro_productos_hasta = filtros.productos_hasta;
            this.filtro_peso_desde = filtros.peso_desde;
            this.filtro_peso_hasta = filtros.peso_hasta;
            this.filtro_volumen_desde = filtros.volumen_desde;
            this.filtro_volumen_hasta = filtros.volumen_hasta;
            this.validar();
        },

        isObjEmpty(obj){
            return Object.keys(obj).length === 0;
        },

        irFiltro(){
            this.$refs.modalFiltro.toggle();
        },
        verDetalle(item){
            console.log(item);
            this.$refs.modalDetalle.toggle(item.id);
        }
    }
}
</script>
<style lang="scss" scoped>
.box-search{
    width: 520px;
    height: 330px;
    background-color: #FFFFFF;
    border-radius: 8px;
    display: flex;
    align-items: center;
}
</style>
